

.wrapper{
    width: 100%;
    margin: 50px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
    padding: 50px;
    justify-content: center;
}

.slika{
    margin: 50px;
    display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
    display: flex;
    justify-content: center;
    border-radius: 50%;
    background-size: 150px;
    width: 400px;
    height: 400px;
    border: 2px solid;
    padding: 50px;
    justify-content: center;
}

.li{
    font-size: 40px;
    align-content:center ;
    float: left;
}

.orodje ul{
   
    justify-content: center;
    list-style-type: none;
    padding: 50px;
    justify-content: center;
    margin-bottom: 80px;
}




.opis{
    background-color: #939292;
    color: aliceblue;
    padding: 20px;
    text-align: center;
    border-radius: 20%;
    padding: 50px;
    margin: 50px;
    justify-content: center;

}
@media (max-width: 700px){
   
    .wrapper {
        width:100%;
        text-align: center;
        margin: 10px;
    }
   
}