li {
    color: grey;
    margin: 0 0.8rem;
    font-size: 1rem;
    position: relative;
    list-style: none;
    text-decoration:none;

  }
.kazalo{
    text-decoration:none; 
}

  .current {
    li {
      border-bottom: 2px solid black;
    }
  }